import React, { useState } from "react"
import BlockContent from "@sanity/block-content-to-react"
import FsLightbox from "fslightbox-react"
import Img from "gatsby-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default props => {
  const [lb, setLb] = useState({
    toggler: false,
    slide: 1,
  })

  function openLightboxOnSlide(number) {
    setLb({
      toggler: !lb.toggler,
      slide: number,
    })
  }

  return (
    <div className="container space-2">
      {props.data.title && (
        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <span className="d-block small font-weight-bold text-cap mb-2">
            {props.data.subtitle}
          </span>
          <h2>{props.data.title}</h2>
        </div>
      )}

      <FsLightbox
        toggler={lb.toggler}
        slide={lb.slide}
        sources={[props.data.video]}
      />

      <div className="row justify-content-lg-between">
        <div className="col-lg-6 mb-5 mb-md-0">
          <div
            className="position-relative max-w-50rem mx-auto video"
            role="button"
            onClick={() => openLightboxOnSlide(1)}
            onKeyDown={() => openLightboxOnSlide(1)}
            tabIndex={0}
          >
            <Img
              className="img-fluid"
              fluid={props.data.image}
              alt="Instagram Post"
            />
            <FontAwesomeIcon
              icon={["far", "play-circle"]}
              className="text-white thumb h1"
            />
          </div>
        </div>
        <div className="col-lg-5 mb-0">
          <div className="mb-0">
            <BlockContent blocks={props.data.text} />
            {props.data.button_text && (
              <div className="text-left">
                <a
                  className="btn btn-primary transition-3d-hover text-center mt-4 mb-1 mb-sm-0 mx-1"
                  rel="noopener noreferrer"
                  href={props.data.button_url}
                  target="_blank"
                  tabIndex={-10}
                >
                  {props.data.button_text}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
