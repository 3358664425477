import React from "react"
import { useShows } from "../hooks/useshows"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default props => {
  const data = useShows()
  return (
    <>
      {data.length > 0 && (
        <div className="container space-2">
          <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
            <span className="d-block small font-weight-bold text-cap mb-2">
              Upcoming Shows
            </span>
            <h2>See Rajiv Live</h2>
          </div>
          <div className="w-lg-75 mx-lg-auto mb-5 mb-md-7">
            {data.map((item, key) => (
              <a
                className="card card-frame py-3 px-4 mb-3"
                href={item.link}
                key={key}
              >
                <div className="row align-items-sm-center">
                  <span className="col-sm-6 text-dark">{item.date}</span>
                  <span className="col-6 col-sm-3 text-body">
                    {item.location}
                  </span>
                  <span className="col-6 col-sm-3 text-right">
                    More Info
                    <FontAwesomeIcon
                      icon={["fas", "angle-right"]}
                      className="ml-1"
                    />
                  </span>
                </div>
              </a>
            ))}
          </div>
          {props.frontPage && (
            <div className="text-center">
              <Link to="/live">View All Shows</Link>
            </div>
          )}
        </div>
      )}
    </>
  )
}
