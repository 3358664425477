import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Hero from "../components/hero"
import UpcomingShows from "../components/upcoming_shows"
import { Helmet } from "react-helmet"
import VideoFeature from "../components/VideoFeature"

export default data => {
  const video_highlight = {
    text: data.data.sanityPages._rawVideoHighlight.text,
    image: data.data.sanityPages.video_highlight.video.cover_image.asset.fluid,
    video: data.data.sanityPages.video_highlight.video.url,
    title: data.data.sanityPages.video_highlight.title,
    subtitle: data.data.sanityPages.video_highlight.subtitle,
    button_text: data.data.sanityPages.video_highlight.button_text,
    button_url: data.data.sanityPages.video_highlight.button_url,
  }

  return (
    <Layout>
      <Helmet title={`Rajiv Satyal - ${data.data.sanityPages.title}`} />
      <Hero data={data.data.sanityPages} />
      <UpcomingShows />
      <VideoFeature data={video_highlight} />
    </Layout>
  )
}

export const query = graphql`
  query LiveQuery {
    sanityPages(slug: { current: { eq: "tour" } }) {
      video_highlight {
        title
        subtitle
        button_text
        button_url
        video {
          url
          cover_image {
            asset {
              fluid(maxWidth: 350) {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      _rawVideoHighlight(resolveReferences: { maxDepth: 10 })

      id
      hero_subtitle
      hero_title
      hero_video
      title
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
    }
  }
`
