import { useStaticQuery, graphql } from "gatsby"

export const useShows = () => {
  const { allSanityShow } = useStaticQuery(
    graphql`
      query ShowsQuery {
        allSanityShow(sort: { fields: date, order: ASC }) {
          nodes {
            date(formatString: "MMMM DD, YYYY")
            link
            title
            location
          }
        }
      }
    `
  )
  let futureShows = allSanityShow.nodes.filter(show => {
    return new Date(show.date) > Date.now()
  })
  return futureShows
}
